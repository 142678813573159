<template>
  <div>
    <p class="u-mb-4">
      Pour effectuer cette action, connectez-vous ou inscrivez-vous.
    </p>
    <div class="o-row u-ai-c u-jc-sb u-jc-fe@md">
      <div class="o-col u-auto">
        <ButtonSimple
          type="button"
          label="Se connecter"
          outline
          @click="openLoginModal"
        />
      </div>
      <div class="o-col u-auto">
        <ButtonSimple
          route="/inscription"
          label="S'inscrire"
          @click="
            gtag('event', 'popup_identification', {
              cta_name: `S'inscrire`,
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { gtag } = useGtag()

const emit = defineEmits<{
  openLoginModal: []
}>()

const openLoginModal = () => {
  gtag('event', 'popup_identification', {
    cta_name: 'Se connecter',
  })
  emit('openLoginModal')
}
</script>
