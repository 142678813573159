import { useModalSlot } from 'vue-final-modal'
import LoginModal from '@/components/modal/LoginModal.vue'
import CheckLoginModal from '@/components/modal/CheckLoginModal.vue'
import BadgeModal from '@/components/modal/BadgeModal.vue'
import ForgottenPasswordModal from '@/components/modal/ForgottenPasswordModal.vue'
import { useCustomModal } from '~/modalHelper'

const resetLoginModal = () => {
  checkLoginModal.patchOptions(getLoginModalConfig())
}

const getForgotPasswordModalConfig = () => ({
  attrs: {
    modalId: 'password',
    title: 'Mot de passe oublié ?',
    clickToClose: false,
    onClosed: resetLoginModal,
  },
  slots: {
    default: useModalSlot({
      component: ForgottenPasswordModal,
      attrs: {
        onOpenLoginModal: resetLoginModal,
      },
    }),
  },
})

const getBadgeModalConfig = () => ({
  attrs: {
    modalId: 'badge',
    title: 'Mon badge héros',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: BadgeModal,
    }),
  },
})

export const badgeModal = useCustomModal(getBadgeModalConfig())

const onOpenForgottenPassword = async () => {
  checkLoginModal.patchOptions(getForgotPasswordModalConfig())
}

const getLoginModalConfig = () => ({
  attrs: {
    modalId: 'login',
    title: 'Connexion',
    clickToClose: false,
    onClosed: resetCheckLoginModal,
  },
  slots: {
    default: useModalSlot({
      component: LoginModal,
      attrs: {
        onOpenForgottenPassword,
      },
    }),
  },
})

const onOpenLoginModal = async () => {
  checkLoginModal.patchOptions(getLoginModalConfig())
}

const getCheckLoginModalConfig = () => ({
  attrs: {
    modalId: 'check_login',
    title: 'Identifiez-vous',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: CheckLoginModal,
      attrs: {
        onOpenLoginModal,
      },
    }),
  },
})

export const checkLoginModal = useCustomModal(getCheckLoginModalConfig())

const resetCheckLoginModal = () => {
  checkLoginModal.patchOptions(getCheckLoginModalConfig())
}
