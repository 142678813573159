<template>
  <div>
    <p>Choisissez le badge que vous souhaitez arborer fièrement</p>
    <div
      class="hr"
      :class="{ 'is-loading': status === 'pending' }"
    />
    <div class="filters">
      <span
        :class="{ 'is-active': filterBadges === filters.ALL }"
        @click="changeFilter(filters.ALL)"
      >
        Tous ({{ badgesCount[filters.ALL] }})
      </span>
      <span
        :class="{ 'is-active': filterBadges === filters.GOLD }"
        @click="changeFilter(filters.GOLD)"
      >
        Badges Or ({{ badgesCount[filters.GOLD] }})
      </span>
      <span
        :class="{ 'is-active': filterBadges === filters.SILVER }"
        @click="changeFilter(filters.SILVER)"
      >
        Argent ({{ badgesCount[filters.SILVER] }})
      </span>
      <span
        :class="{ 'is-active': filterBadges === filters.BRONZE }"
        @click="changeFilter(filters.BRONZE)"
      >
        Bronze ({{ badgesCount[filters.BRONZE] }})
      </span>
    </div>
    <div
      v-if="badgesPaged?.length"
      class="p-badges"
    >
      <div
        v-if="status === 'pending'"
        class="backdrop"
      />
      <div
        v-for="badge in badgesPaged"
        :key="badge.id"
        class="p-badge"
        :class="{ 'is-selected': badge.id === badgeDisplayed }"
        @click="
          () => {
            selectedBadgeId = badge.id
          }
        "
      >
        <div>
          <p class="p-badge__name">{{ badge.name }}</p>
          <p class="p-badge__desc">{{ badge.description }}</p>
          <p class="p-badge__unlock">
            Obtenus par {{ badge.unlock_percent }}% des joueurs
          </p>
        </div>
        <div
          class="p-badge__image"
          :class="[badge.type.slug]"
        >
          <img
            v-if="badge?.image?.url"
            :src="badge.image.url"
            loading="lazy"
          />
        </div>
      </div>
      <ThePagination
        :total-pages="pagesCount"
        :current-page="currentPage"
        :go-to="changePageTo"
        :routing="false"
      />
    </div>
    <div v-else>Aucun badge disponible</div>
  </div>
</template>

<script setup lang="ts">
const { data, getTempSession } = useTempAuth()

const ALL = 'all'
const GOLD = 'gold'
const SILVER = 'silver'
const BRONZE = 'bronze'

const selectedBadgeId = ref<number>()
const filters = {
  ALL,
  GOLD,
  SILVER,
  BRONZE,
} as const

const filterBadges = ref(ALL)
const currentPage = ref(1)
const perPage = 6

const badges = computed(() => {
  return (
    data.value?.badges
      ?.filter((item) => item.percent === 100)
      .map((item) => item.badge) ?? []
  )
})

const badgesFiltered = computed(() => {
  return badges.value.filter(
    (badge) =>
      filterBadges.value === ALL.toString() ||
      badge.type.slug === filterBadges.value,
  )
})

const badgesPaged = computed(() => {
  const range = (currentPage.value - 1) * perPage
  return badgesFiltered.value.slice(range, range + perPage)
})

const badgesCount = computed(() => {
  return {
    [ALL]: badges.value.length,
    [GOLD]: badges.value.filter((b) => b.type.slug === GOLD).length,
    [SILVER]: badges.value.filter((b) => b.type.slug === SILVER).length,
    [BRONZE]: badges.value.filter((b) => b.type.slug === BRONZE).length,
  }
})

const badgeDisplayed = computed(() => {
  return data.value?.badge_displayed?.badge?.id ?? 0
})

const pagesCount = computed(() => {
  return Math.ceil(badgesFiltered.value.length / perPage) || 1
})

const changePageTo = (index: number) => {
  if (index > 0) {
    currentPage.value = index
  }
}

const changeFilter = (filter: string) => {
  changePageTo(1)
  filterBadges.value = filter
}

const { status } = await useCustomFetch(API.PUT__UPDATE_DISPLAYED_BADGE, {
  method: 'PUT',
  immediate: false,
  body: {
    badge: selectedBadgeId,
  },
  onResponse: () => getTempSession({ force: true }),
  onResponseError: console.error,
})
</script>

<style lang="scss" scoped>
.hr {
  position: relative;
  height: 1px;
  margin: 2.5rem 0;
  overflow: hidden;
  background-color: rgba(0 0 0 / 0.15);

  &.is-loading {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -25%;
      display: block;
      width: 25%;
      height: 100%;
      background-color: $blue;
      animation: loading 0.7s infinite linear;
    }
  }
}

.filters {
  display: flex;
  margin-bottom: 4rem;

  span {
    cursor: pointer;
    opacity: 0.25;

    &:not(:last-of-type) {
      margin-right: 3rem;
    }

    &.is-active {
      color: $blue;
      opacity: 1;
    }
  }
}

.p-badges {
  position: relative;
  margin: 0 -2rem;

  @media (min-width: 992px) {
    margin: 0 -8rem;
  }
}

.p-badge {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;

  @media (min-width: 992px) {
    padding: 1.5rem 8rem;
  }

  &::after {
    content: '';
    position: absolute;
    right: auto;
    bottom: -1px;
    left: auto;
    width: calc(100% - 4rem);
    height: 1px;
    background: #f2f2f2;

    @media (min-width: 992px) {
      width: calc(100% - 16rem);
    }
  }

  &.is-selected,
  &:hover {
    color: $blue;
    background: #f2f2f2;
    cursor: pointer;

    &::after {
      content: none;
    }
  }

  &__name {
    font-size: 1.8rem;
  }

  &__desc {
    font-size: 1.2rem;
  }

  &__unlock {
    margin-top: 1.2rem;
    font-size: 1rem;
  }

  &__image {
    width: 10.5rem;
    height: 10.5rem;
    overflow: hidden;
    border-radius: 50%;

    &.gold {
      background-image: radial-gradient(circle at 75%, #ffdd67, #ffcb17);
      border: 2px solid #fcc402;
    }

    &.silver {
      background-image: radial-gradient(circle at 75%, #cecece, #a3a3a3);
      border: 2px solid #a5a5a5;
    }

    &.bronze {
      background-image: radial-gradient(circle at 75%, #f0aa53, #d88520);
      border: 2px solid #d38220;
    }

    .is-selected & {
      background-color: $blue;
      background-image: none;
      border-color: $blue;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right center;
    }
  }
}

.backdrop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

@keyframes loading {
  0% {
    left: -25%;
  }

  90%,
  100% {
    left: 100%;
  }
}
</style>
